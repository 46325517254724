const ImagePopap: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width='355'
      height='474'
      viewBox='0 0 355 474'
      fill='none'
      className={className}
    >
      <path
        d='M125.446 272.508C121.287 165.909 66.6604 124.528 39.8671 117.162C-50.8278 91.4299 -172.413 196.426 -226.017 210.993C-336.789 241.095 -478.97 519.711 -394.851 593.643C-358.945 625.201 -270.721 672.29 -205.078 608.177C-139.436 544.064 -64.7013 564.68 -35.5392 583.002C-1.96164 605.076 70.9229 637.738 93.8401 591.798C116.757 545.858 137.038 527.696 144.313 524.357C229.673 491.914 263.306 457.611 269.452 444.515C281.165 419.484 286.935 364.797 216.313 346.291C145.691 327.784 126.309 289.391 125.446 272.508Z'
        fill='#EFF3FC'
      />
      <path
        d='M97.9013 311.971C81.0787 276.643 97.0602 219.587 107.154 195.474H152.575L174.444 290.522C173.435 304.317 189.725 378.981 197.996 414.589L196.314 419.215L168.556 374.635C148.033 354.111 149.35 322.064 152.575 308.606C148.537 305.915 128.182 259.26 118.509 236.269L113.042 412.906H107.995L92.434 359.074C83.6863 334.177 92.434 317.298 97.9013 311.971Z'
        fill='#052195'
      />
      <path
        d='M105.051 422.579H118.509V421.318L112.621 412.906H107.995L105.051 422.579Z'
        fill='#263B7F'
      />
      <path
        d='M196.314 419.215L198.417 423H219.865L208.09 415.43L198.417 414.589L196.314 419.215Z'
        fill='#263B7F'
      />
      <path
        d='M55.0039 160.568C55.3404 157.876 73.2284 135.614 81.0789 126.081C86.9668 130.848 98.2379 141.474 96.2192 144.166C94.2005 146.857 83.1817 156.923 78.9761 160.568C83.0135 165.614 94.6772 187.904 100.004 198.418C101.126 197.577 103.705 196.147 105.051 197.157C106.397 198.166 105.612 199.54 105.051 200.101H101.266L103.392 201.923C103.501 200.597 103.964 201.762 104.21 202.624L103.392 201.923C103.377 202.107 103.369 202.338 103.369 202.624C103.369 204.979 100.004 209.493 98.3221 211.456L95.3781 205.147C88.2285 200.942 54.5833 163.932 55.0039 160.568Z'
        fill='#F1B5AB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M119.24 65.8804C113.884 59.593 110.258 51.8876 110.864 43.2535C112.61 18.3763 147.239 23.849 153.976 36.4049C160.712 48.9607 159.912 80.8097 151.206 83.0538C147.734 83.9488 140.337 81.7565 132.81 77.2193L137.535 110.647L124.814 102.58L119.24 65.8804Z'
        fill='#F1B5AB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M128.896 64.1312C125.436 70.7719 120.652 75.7728 115.871 75.7728C106.792 75.7728 100.336 63.6336 100.336 50.5818C100.336 50.1232 100.345 49.6677 100.363 49.2156C90.7583 55.8065 83.3561 68.798 70.6476 68.798C53.3577 68.798 46.4912 33.9243 70.6476 33.9243C87.0757 33.9243 95.1567 36.3195 102.121 39.8615C103.494 35.9892 105.589 32.7335 108.15 30.46C108.766 29.7664 109.711 29.1029 110.929 28.4877C111.674 28.0801 112.445 27.7481 113.238 27.4982C117.438 25.9548 123.521 24.8661 130.316 24.6288C131.585 24.5845 132.832 24.5716 134.047 24.5879C134.067 18.1847 139.264 13 145.672 13C152.092 13 157.296 18.2045 157.296 24.6246C157.296 30.8334 152.429 35.9053 146.302 36.2324C142.797 37.3139 138.293 38.108 133.299 38.4429C133.784 40.2664 134.047 42.3379 134.047 44.6788C134.047 46.7459 133.819 48.938 133.399 51.1639C132.316 49.8764 130.867 49.0362 129.397 49.0362C125.285 49.0362 122.423 52.4192 122.423 56.011C122.423 60.5696 125.054 63.8674 128.896 64.1312Z'
        fill='#191847'
      />
      <path
        d='M122.715 92.4361C107.911 92.0997 87.3874 116.128 78.9761 128.184C87.3874 131.885 92.8547 141.502 94.537 145.848C97.565 143.156 102.808 137.717 105.051 135.334C110.771 166.287 115.565 177.11 117.247 178.652C136.089 181.68 160.425 148.231 170.239 131.128C158.799 120.698 151.453 109.119 149.21 104.632C146.182 96.2212 138.696 94.1184 135.332 94.1184V99.5857C130.285 100.595 124.818 95.2399 122.715 92.4361Z'
        fill='#98D8EA'
      />
      <path
        d='M182.435 295.989C174.36 301.709 110.659 300.335 79.8171 298.933C85.2004 220.204 104.49 185.101 113.462 177.39C113.126 172.343 116.967 145.848 118.93 133.231C123.304 135.25 155.519 132.39 171.08 130.707C173.771 143.156 160.706 153.839 153.837 157.624C151.481 164.016 171.921 252.531 182.435 295.989Z'
        fill='#738FFD'
      />
      <path
        d='M186.641 160.147C183.697 178.231 168.556 171.923 166.033 167.717C160.986 159.306 152.575 126.922 150.893 120.193C149.21 113.464 149.21 102.53 147.949 102.53C146.687 102.53 141.64 97.4829 142.061 93.6979C142.397 90.6698 148.089 88.5109 150.893 87.8099C151.734 84.4454 160.986 92.8567 158.463 93.6979C156.444 94.3708 155.939 99.8661 155.939 102.53C157.285 105.558 167.715 121.175 172.762 128.605C185.211 143.072 187.201 155.661 186.641 160.147Z'
        fill='#F1B5AB'
      />
    </svg>
  );
};

export default ImagePopap;
